export const copyToClipboard = (value: string) => {
  let result: boolean;

  if (navigator) {
    navigator.clipboard
      .writeText(value)
      .then(
        () => {
          result = true;
        },
        () => {
          result = false;
        },
      )
      .catch(() => {
        result = false;
      })
      .finally(() => {
        if (!result) {
          const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
          const copyHotkey = isMac ? "⌘C" : "CTRL+C";
          result = !!prompt(`Press ${copyHotkey}`, value); // eslint-disable-line no-alert
          if (!result) {
            return false;
          }
        }
      });
  }

  return true;
};

import React from "react";

function HomeIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M3 18V8.177c0-.727.394-1.396 1.029-1.749l7-3.888c.604-.336 1.338-.336 1.942 0l7 3.888C20.606 6.781 21 7.45 21 8.177V18c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2z" />
      </g>
    </svg>
  );
}

export default HomeIcon;

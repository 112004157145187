import React, { HTMLAttributes } from "react";

function AddUserIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M9.3 12.889c3.48 0 6.3 2.786 6.3 6.222 0 .491-.403.889-.9.889H3.9a.894.894 0 0 1-.9-.889c0-3.436 2.82-6.222 6.3-6.222zM9.3 4c1.988 0 3.6 1.592 3.6 3.556 0 1.963-1.612 3.555-3.6 3.555S5.7 9.52 5.7 7.556C5.7 5.592 7.312 4 9.3 4zm9 0c.497 0 .9.398.9.889v.889h.9c.497 0 .9.398.9.889 0 .49-.403.889-.9.889h-.9v.888c0 .491-.403.89-.9.89a.894.894 0 0 1-.9-.89v-.888h-.9a.894.894 0 0 1-.9-.89c0-.49.403-.888.9-.888h.9v-.89c0-.49.403-.888.9-.888z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default AddUserIcon;

import { ChangeEvent } from "react";

import { TFunction } from "react-i18next";

import { appSettings } from "@/common/constants";

export const bytesToMBConverter = (sizeInBytes: any) => (sizeInBytes / 1024 / 1024).toFixed(2);

export const extractExtensionFromFilename = (filename: any) => {
  const lastIndexOfDot = filename.lastIndexOf(".");
  return lastIndexOfDot < 0 ? "" : filename.substring(lastIndexOfDot);
};

export const validate = (
  e: ChangeEvent<HTMLInputElement>,
  translation: TFunction<"translation">,
) => {
  const [file] = e?.target.files || [];
  if (!file) {
    return { isValid: false };
  }
  const maximumSize = appSettings.uploadPicture.pictureMaxSizeInBytes;
  if (!file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
    return {
      isValid: false,
      errorMessage: translation("Generic.FileExtensionNotSupported"),
    };
  }

  if (file.size > maximumSize) {
    return {
      isValid: false,
      errorMessage: translation("Generic.FileTooLarge10MB"),
    };
  }
  return { isValid: true };
};

import React, { HTMLAttributes } from "react";

export function ShareOpinionIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M6.05 4h11.9c1.408 0 2.55 1.13 2.55 2.526v12.632a.842.842 0 0 1-.433.733.859.859 0 0 1-.85-.008L15.4 17.591a.85.85 0 0 0-.468-.118H6.05c-1.408 0-2.55-1.132-2.55-2.527v-8.42C3.5 5.13 4.642 4 6.05 4"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

import React, { HTMLAttributes } from "react";

function PetitionsIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M18.125 19.195c.483 0 .875.404.875.902 0 .499-.392.903-.875.903H5.878c-.483 0-.874-.404-.874-.903 0-.498.391-.902.874-.902zM13.751 3.426c.679-.591 1.68-.564 2.327.063l2.397 2.474c.67.658.698 1.752.061 2.446l-7.873 8.123c-.285.292-.658.474-1.058.515l-3.648.343h-.079c-.232.001-.456-.093-.62-.262-.185-.19-.278-.455-.254-.722l.376-3.764c.04-.412.216-.798.498-1.092zm1.111 1.327l-1.705 1.805 2.344 2.42 1.75-1.76-2.389-2.465z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default PetitionsIcon;

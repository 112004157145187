import React from "react";

function ArticlesIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M18.773 7.797l-3.886-4.5c-.165-.188-.4-.296-.647-.297h-7c-.587-.007-1.153.226-1.573.648-.42.422-.66.998-.667 1.602v13.5c.007.604.247 1.18.667 1.602.42.422.986.655 1.573.648h9.52c.587.007 1.153-.226 1.573-.648.42-.422.66-.998.667-1.602V8.4c0-.223-.082-.438-.227-.603zM9.375 12H12c.483 0 .875.403.875.9s-.392.9-.875.9H9.375c-.483 0-.875-.403-.875-.9s.392-.9.875-.9zm5.25 5.4h-5.25c-.483 0-.875-.403-.875-.9s.392-.9.875-.9h5.25c.483 0 .875.403.875.9s-.392.9-.875.9zm-.254-9c-.375-.038-.651-.378-.621-.765V4.8l3.273 3.6H14.37z" />
      </g>
    </svg>
  );
}

export default ArticlesIcon;

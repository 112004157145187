import React from "react";

const GroupsIcon = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M11.936 13.296c3.002 0 5.436 2.235 5.436 4.991 0 .361-.292.66-.671.706l-.106.007H7.277c-.43 0-.777-.32-.777-.713 0-2.756 2.434-4.99 5.436-4.99zm7.806-.13c1.8 0 3.258 1.372 3.258 3.063 0 .242-.208.438-.465.438h-2.327c-.404-1.215-1.138-2.28-2.097-3.09.48-.26 1.037-.41 1.631-.41zm-15.484 0c.594 0 1.151.15 1.63.411-.958.81-1.692 1.875-2.096 3.09H1.465c-.257 0-.465-.196-.465-.438 0-1.691 1.459-3.062 3.258-3.062zm15.075-5.833c1.35 0 2.445 1.045 2.445 2.334 0 1.288-1.095 2.333-2.445 2.333-1.35 0-2.444-1.045-2.444-2.333 0-1.289 1.094-2.334 2.444-2.334zm-14.666 0c1.35 0 2.444 1.045 2.444 2.334C7.111 10.955 6.017 12 4.667 12c-1.35 0-2.445-1.045-2.445-2.333 0-1.289 1.095-2.334 2.445-2.334zM12 5c1.688 0 3.056 1.306 3.056 2.917 0 1.61-1.368 2.916-3.056 2.916S8.944 9.527 8.944 7.917C8.944 6.306 10.312 5 12 5z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default GroupsIcon;

import React from "react";

function VideoIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M20.99 7.15c-.62-.28-1.348-.162-1.849.3l-2.148 2V8c0-1.657-1.343-3-2.999-3H4.999C3.342 5 2 6.343 2 8v8c0 1.657 1.342 3 2.999 3h8.995c1.656 0 2.999-1.343 2.999-3v-1.45l2.158 2c.318.288.731.448 1.16.45.238 0 .473-.052.69-.15.603-.244.998-.83.999-1.48V8.63c-.003-.653-.403-1.24-1.01-1.48z" />
      </g>
    </svg>
  );
}

export default VideoIcon;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyApYY7YHqGtaRwozaJAlsKlf60_zUXRcmw",
  authDomain: "politicall-8fe78.firebaseapp.com",
  projectId: "politicall-8fe78",
  storageBucket: "politicall-8fe78.appspot.com",
  messagingSenderId: "618833817339",
  appId: "1:618833817339:web:89d92d11fbc73787f98a5e",
  measurementId: "G-VEGK0XNR34",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;

import React from "react";

function HeartIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M12 20c-.24.001-.47-.097-.639-.273L4.37 12.404c-1.825-1.929-1.825-5.035 0-6.964 1.84-1.92 4.819-1.92 6.66 0L12 6.456l.972-1.016c1.84-1.92 4.819-1.92 6.659 0 1.825 1.929 1.825 5.035 0 6.964l-6.992 7.323c-.17.176-.4.274-.639.273z" />
      </g>
    </svg>
  );
}

export default HeartIcon;

import React, { HTMLAttributes } from "react";

function LogoutIcon(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M6.647 5c.487 0 .882.392.882.875a.879.879 0 0 1-.882.875h-.883v10.5h.883c.487 0 .882.392.882.875a.879.879 0 0 1-.882.875H4.882A.879.879 0 0 1 4 18.125V5.875C4 5.392 4.395 5 4.882 5zm8.478 2.782a.887.887 0 0 1 1.226.21l2.488 3.5a.87.87 0 0 1-.018 1.033l-2.647 3.5a.885.885 0 0 1-1.579-.401.87.87 0 0 1 .168-.649l1.588-2.1H9.293A.879.879 0 0 1 8.411 12c0-.483.395-.875.882-.875h7.137l-1.517-2.117a.87.87 0 0 1 .212-1.225z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default LogoutIcon;

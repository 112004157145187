import { useRouter } from "next/router";

import { useApolloClient } from "@apollo/client";
import app from "firebase.js";
import { getAuth, signOut } from "firebase/auth";

import { useShowSlackFeedback } from "@/slack-feedback/components/SlackFeedbackProvider";

import { useAuthenticationStore } from "../../authentication/hooks";

export enum AppBarActions {
  Search,
  Bookmarks,
  Notifications,
  Menu,
  Settings,
  InviteFriends,
  Help,
  Logout,
  Profile,
  Donate,
  ProfileMenu,
  Chat,
  Feedback,
}

export const useAppBarActions = (customHandlers: Partial<Record<AppBarActions, () => void>>) => {
  const router = useRouter();
  const { signOut: nonSmSignout } = useAuthenticationStore();
  const apollo = useApolloClient();
  const showSlackFeedback = useShowSlackFeedback();
  const auth = getAuth(app);

  const handlers = {
    [AppBarActions.Search]: () => {},
    [AppBarActions.Bookmarks]: () => {
      router.push("/bookmarks");
    },
    [AppBarActions.Notifications]: () => {},
    [AppBarActions.Menu]: () => {},
    [AppBarActions.Settings]: () => {
      router.push("/settings");
    },
    [AppBarActions.InviteFriends]: () => {
      router.push("/invite-friends");
    },
    [AppBarActions.Donate]: () => {
      router.push("/donate");
    },
    [AppBarActions.Feedback]: () => {
      showSlackFeedback();
    },
    [AppBarActions.Help]: () => {},
    [AppBarActions.Logout]: async () => {
      await signOut(auth).catch(() => {});
      await nonSmSignout().catch(() => {});
      await apollo.resetStore().catch(() => {});
      router.push("/");
    },
    [AppBarActions.ProfileMenu]: () => {},
    [AppBarActions.Profile]: () => {},
    [AppBarActions.Chat]: () => {
      router.push("/chat");
    },
    ...customHandlers,
  } as Record<AppBarActions, () => void>;

  return (action: AppBarActions) => {
    return handlers[action]();
  };
};

export enum BottomBarActions {
  Home,
  Menu,
  Chat,
  Community,
}

import { getI18n } from "react-i18next";

import AddUserIcon from "@/common/icons/AddUserIcon";
import ArticlesIcon from "@/common/icons/ArticlesIcon";
import CalendarIcon from "@/common/icons/CalendarIcon";
import ConversationIcon from "@/common/icons/ConversationIcon";
import GroupsIcon from "@/common/icons/GroupsIcon";
import HomeIcon from "@/common/icons/HomeIcon";
import LogoutIcon from "@/common/icons/LogoutIcon";
import PetitionsIcon from "@/common/icons/PetitionsIcon";
import SettingsIcon from "@/common/icons/SettingsIcon";
import { ShareOpinionIcon } from "@/common/icons/ShareOpinionIcon";

import { CDN_HOST, MOBILE_APPSTORE_URL_EN, MOBILE_APPSTORE_URL_FR } from "@/common/constants";
import { AppBarActions } from "@/common/models/AppBarActions";
import { LanguageCodes } from "@/common/models/LanguageCodes";
import { MainAppRoute } from "@/common/models/MainAppRoute";
import { SecondaryNavItem } from "@/common/models/SecondaryNavItem";
import { clearCookie, cookieNames, setCookie } from "@/common/services/cookies";
import { copyToClipboard } from "@/common/services/copyToClipboard";
import {
  convertToLocalTimezone,
  distanceToNowShort,
  getFormattedDistanceToNow,
  getLocalDateFromUtc,
  getRelativeDate,
  getUTCDateFromString,
} from "@/common/services/dateFormat";
import { extractExtensionFromFilename } from "@/common/services/uploadImageUtils";
import { ActivityFragment, GeographicalInterestsList, SessionFragment } from "@/graphql/types";

import HeartIcon from "../icons/HeartIcon";
import VideoIcon from "../icons/VideoIcon";

export const mainRoutes: MainAppRoute[] = [
  {
    id: "home",
    label: "Main.Home",
    link: "/",
    icon: HomeIcon,
  },
  {
    id: "groups",
    label: "Main.Groups",
    link: "/groups",
    icon: GroupsIcon,
  },
  {
    id: "debates",
    label: "Main.Debates",
    link: "/debates",
    icon: ConversationIcon,
  },
  {
    id: "events",
    label: "Main.Events",
    link: "/events",
    icon: CalendarIcon,
  },
  {
    id: "blogs",
    label: "Main.Blogs",
    link: "/blogs",
    icon: ArticlesIcon,
  },
  {
    id: "petitions",
    label: "Main.Petitions",
    link: "/petitions",
    icon: PetitionsIcon,
  },
  {
    id: "liveStreams",
    label: "Main.LiveStreams",
    link: "/livestreams",
    icon: VideoIcon,
  },
];

export const secondaryNavItems: SecondaryNavItem[] = [
  {
    label: "Main.Settings",
    action: AppBarActions.Settings,
    icon: SettingsIcon,
  },
  {
    label: "Main.InviteFriends",
    action: AppBarActions.InviteFriends,
    icon: AddUserIcon,
  },
  {
    label: "Monetization.Donations.Donate",
    action: AppBarActions.Donate,
    icon: HeartIcon,
  },
  {
    label: "SlackFeedback.SendFeedbackMenuButton",
    action: AppBarActions.Feedback,
    icon: ShareOpinionIcon,
  },
  {
    label: "Main.Logout",
    action: AppBarActions.Logout,
    icon: LogoutIcon,
  },
];

export function lighten(color: string, percent: number) {
  if (percent < 0 || percent > 1) {
    throw new Error("Percent parameter requires a value between 0 and 1");
  }
  return `rgba(${parseRgb(color).join(",")},${percent})`;
}

export function parseRgb(color: string) {
  const match = color.match(/^#([0-9a-f]{6})$/i);
  const m = match ? match[1] : null;
  if (!m) {
    throw new Error("Color needs to be in #rrggbb format");
  }
  return [parseInt(m.substr(0, 2), 16), parseInt(m.substr(2, 2), 16), parseInt(m.substr(4, 2), 16)];
}

export function matchRoute(
  routes: { link?: string; exact?: boolean; related?: string[] }[],
  route: string,
): any | undefined {
  return [...routes]
    .reverse()
    .find(
      ({ link, exact, related }) =>
        (link && (exact ? route === link : route.startsWith(link || ""))) ||
        (related && related.includes(route)),
    );
}

export const isActivityOwner = (
  activity?: ActivityFragment | null,
  session?: SessionFragment | null,
): boolean => {
  return (
    activity?.author?.id !== undefined &&
    session?.user?.id !== undefined &&
    activity?.author?.id === session?.user?.id
  );
};

export const hasAccessToActivity = (
  activity?: ActivityFragment | null,
  session?: SessionFragment | null,
): boolean => {
  return (
    activity !== undefined &&
    activity !== null &&
    ((session?.user?.id !== undefined && session?.user?.isModerator) ||
      !activity.private ||
      activity.follows.isFollowedByMe)
  );
};

export const onLanguageItemClick = (culture: LanguageCodes) => {
  getI18n().changeLanguage(culture);
};

export const maybeGetCdnPath = (path: string) => {
  return CDN_HOST ? `https://${CDN_HOST}${path}` : path;
};

export const getPathFromUrl = (url: string) => {
  return url.split(/[?#]/)[0];
};

// From "https://stream.mux.com/XX.m3u8" to "https://image.mux.com/XX/thumbnail.jpg?width=640"
export const getVideoPosterFromURL = (
  videoUrl: string | undefined,
  isPoliticAll?: boolean,
): string | undefined => {
  return videoUrl
    ? videoUrl
        .replace("stream.mux.com", "image.mux.com")
        .replace(".m3u8", `/thumbnail.jpg?width=640${isPoliticAll ? "&time=3" : ""}`)
    : undefined;
};

// From "https://stream.mux.com/XX.m3u8" to "https://image.mux.com/XX/animated.gif?width=640"
export const getAnimatedPosterFromURL = (
  videoUrl: string | undefined,
  isPoliticAll?: boolean,
): string | undefined => {
  return videoUrl
    ? videoUrl
        .replace("stream.mux.com", "image.mux.com")
        .replace(".m3u8", `/animated.gif?width=640${isPoliticAll ? "&time=3" : ""}`)
    : undefined;
};

export const getVideoPosterFromPlaybackId = (playbackId: string | undefined) =>
  playbackId ? `https://image.mux.com/${playbackId}/thumbnail.jpg` : undefined;

export const truncateDescription = (str: string, maxLength: number) => {
  if (str.length < maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
};

export const shortenedBadgeValue = (value: number): string | number => (value > 10 ? "9+" : value);

export const appstoreLinkEvent = (platform: "ios" | "android") => {
  if (platform === "ios") {
    // Snapchat
    if ((window as any).snaptr) {
      (window as any).snaptr("track", "CUSTOM_EVENT_1");
    }
    // Tiktok
    if (typeof (ttq as any) !== "undefined" && (ttq as any).page) {
      (ttq as any)
        .instance("CGMN2DRC77U81RF87BL0")
        .track("Ios Click", { content_id: 0, content_name: "IOS" });
    }
    // Twitter: iOS button click
    if ((window as any).twq) {
      (window as any).twq("event", "tw-o7avt-oeku2", {});
    }
    //FB
    if ((window as any).fbq) {
      (window as any).fbq("trackCustom", "iosButtonClick", {});
    }

    // Google Ads
    if ((window as any).gtag) {
      (window as any).gtag("event", "conversion", {
        send_to: "AW-11344424934/ZAzHCOeZj6kZEObfuKEq",
      });
    }
  }
  if (platform === "android") {
    if ((window as any).snaptr) {
      (window as any).snaptr("track", "CUSTOM_EVENT_2");
    }
    if (typeof (ttq as any) !== "undefined" && (ttq as any).page) {
      (ttq as any)
        .instance("CGMN2DRC77U81RF87BL0")
        .track("Android Click", { content_id: 1, content_name: "ANDROID" });
    }
    // Twitter: Android button click
    if ((window as any).twq) {
      (window as any).twq("event", "tw-o7avt-oeku3", {});
    }
    //FB
    if ((window as any).fbq) {
      (window as any).fbq("trackCustom", "AndroidButtonClick", {});
    }
    // Google Ads
    if ((window as any).gtag) {
      (window as any).gtag("event", "conversion", {
        send_to: "AW-11344424934/zBQFCL22kqkZEObfuKEq",
      });
    }
  }
};

export type ApolloGraphqlErrorContext = { graphQLErrors: [{ message: string }] };
export const getApolloGraphqlErrorType = (errorContext: ApolloGraphqlErrorContext) => {
  let error = "";
  if (
    errorContext.graphQLErrors[0].message &&
    errorContext.graphQLErrors[0].message === "This resource doesn't exist."
  ) {
    error = "not_found";
  }
  if (
    errorContext.graphQLErrors[0].message &&
    errorContext.graphQLErrors[0].message === "You blocked activity author."
  ) {
    error = "blocked_by_me";
  }
  if (
    errorContext.graphQLErrors[0].message &&
    errorContext.graphQLErrors[0].message === "You aren't authorized to read it."
  ) {
    error = "blocked_by_admin";
  }

  return error;
};
export const localizedAppstoreURL = (lang: string) =>
  lang === "fr" ? MOBILE_APPSTORE_URL_FR : MOBILE_APPSTORE_URL_EN;

export const getGeoInterestEnumFromString = (location: string | undefined) => {
  let hotTrendGeoInterest: GeographicalInterestsList | undefined;

  if (Object.values(GeographicalInterestsList).includes(location as GeographicalInterestsList)) {
    hotTrendGeoInterest = location as GeographicalInterestsList;
  } else {
    hotTrendGeoInterest = undefined;
  }

  return hotTrendGeoInterest;
};

export const parsedQueryParamStringOrArray = (input: string | string[] | undefined) => {
  if (!input) return "";

  return Array.isArray(input) ? input[0] : input;
};

export const stripMessageHTML = (v?: string) => {
  if (typeof window !== "undefined") {
    const el = document.createElement("html");

    if (v) {
      el.innerHTML = v;
    }

    return el.innerText || "";
  }

  return "";
};

export {
  getLocalDateFromUtc,
  convertToLocalTimezone,
  distanceToNowShort,
  getUTCDateFromString,
  getFormattedDistanceToNow,
  getRelativeDate,
  extractExtensionFromFilename,
  setCookie,
  cookieNames,
  clearCookie,
  copyToClipboard,
};

import React from "react";

function CalendarIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M17.758 4.024h-.984V3.012c0-.559-.44-1.012-.983-1.012-.544 0-.984.453-.984 1.012v1.012H8.903V3.012C8.903 2.453 8.463 2 7.92 2c-.543 0-.983.453-.983 1.012v1.012h-.984C4.322 4.024 3 5.384 3 7.06v12.144c0 1.677 1.322 3.036 2.952 3.036h11.806c1.63 0 2.952-1.36 2.952-3.036V7.06c0-1.677-1.322-3.036-2.952-3.036zM7.92 17.18c-.543 0-.983-.453-.983-1.012 0-.559.44-1.012.983-1.012.544 0 .984.453.984 1.012 0 .559-.44 1.012-.984 1.012zm7.872 0h-3.936c-.543 0-.984-.453-.984-1.012 0-.559.44-1.012.984-1.012h3.936c.543 0 .983.453.983 1.012 0 .559-.44 1.012-.983 1.012zm2.951-6.072H4.968V7.06c0-.559.44-1.012.984-1.012h.984V7.06c0 .559.44 1.012.983 1.012.544 0 .984-.453.984-1.012V6.048h5.904V7.06c0 .559.44 1.012.984 1.012.543 0 .983-.453.983-1.012V6.048h.984c.544 0 .984.453.984 1.012v4.048z" />
      </g>
    </svg>
  );
}

export default CalendarIcon;

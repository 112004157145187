import React from "react";

function ConversationIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M20.855 7.778c1.185 0 2.145.934 2.145 2.087v10.44c0 .25-.14.481-.365.605-.106.059-.227.09-.35.09-.128 0-.254-.034-.364-.097l-3.21-1.893c-.119-.07-.255-.103-.393-.098h-7.47c-1.147 0-2.076-.88-2.134-1.982l.113-.066h7.415c1.741 0 3.158-1.38 3.158-3.074V7.778zM14.388 3c1.207 0 2.186.942 2.186 2.104v7.014c0 1.162-.979 2.104-2.186 2.104H6.773c-.14-.005-.28.03-.4.099L3.1 16.228c-.112.064-.24.099-.371.099-.126-.001-.248-.033-.357-.092-.23-.124-.371-.357-.372-.61V5.105C2 3.941 2.979 3 4.186 3z" />
      </g>
    </svg>
  );
}

export default ConversationIcon;

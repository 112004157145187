import { formatDistanceToNowStrict, formatRelative } from "date-fns";
import { enUS as en, fr } from "date-fns/locale";
import moment from "moment";

import ActivityModel from "@/common/models/ActivityModel";

import i18n from "./i18n";

require("moment.distance");

const dateFormatWithSlash = "DD/MM/YYYY";

const timeFormat = "hh:mm A";

const defaultFormat = (date: moment.MomentInput) =>
  moment.utc(date).local().format("DD/MM/YYYY hh:mm A");

export default defaultFormat;

export const dateFormat = (date: moment.MomentInput) => moment(date).format(dateFormatWithSlash);

export const todayAsString = () => moment().format(dateFormatWithSlash);

export const tomorrowAsString = () => moment().add(1, "days").format(dateFormatWithSlash);

export const theNextDayAfterTomorrowAsString = () =>
  moment().add(2, "days").format(dateFormatWithSlash);

export const convertToDate = (date: moment.MomentInput) => moment(date, dateFormatWithSlash);

export const niceDateFormat = (date: moment.MomentInput, locale = "en") =>
  moment(date).locale(locale).format("MMMM Do, YYYY");

export const dateFormatForDateInput = (date: moment.MomentInput) =>
  moment(date).format("YYYY-MM-DD");

// constant added to convert JS epoch time (1970) to .NET epoch time (0001)
export const datetimeToTicks = (jsDatetime: Date) =>
  jsDatetime.getTime() * 10000 + 621355968000000000;

export const datetimeTicksNow = () => datetimeToTicks(new Date());

export const momentToTicks = (momentDatetime: moment.Moment) =>
  datetimeToTicks(momentDatetime.toDate());

export const dateFromTicks = (ticks: number) =>
  moment(new Date((ticks - 621355968000000000) / 10000));

export const getDateAndTimeStringsFromTicks = (ticks: any) => {
  const datetime = dateFromTicks(ticks);

  return {
    date: datetime.format(dateFormatWithSlash),
    time: datetime.format(timeFormat),
  };
};

export const dateAndTimeStringsToMoment = (dateString: any, timeString: moment.MomentInput) => {
  const datetime = convertToDate(dateString);
  const time = moment(timeString, timeFormat);
  datetime.hours(time.hours());
  datetime.minutes(time.minutes());

  return datetime;
};

export const getTicksFromDateAndTimeStrings = (dateString: any, timeString: any) =>
  momentToTicks(dateAndTimeStringsToMoment(dateString, timeString));

export const dateTimeUtcNow = () => moment.utc();

const calculateAge = (date: moment.MomentInput) => moment.utc().diff(date, "years");

export const calculateNumberOfDaysBeforeNow = (date: moment.MomentInput) =>
  moment.utc().diff(date, "days");

export const calculateNumberOfHoursBeforeNow = (date: moment.MomentInput) =>
  moment.utc().diff(date, "hours");

export const birthdateFormat = (
  date: any,
  t: (arg0: string, arg1: { age: number }) => any,
  culture: string | boolean | moment.Moment | moment.Duration | string[],
) => {
  if (!date || !date.isValid()) {
    return null;
  }

  const localeDate = moment(date).locale(culture);
  const formatedDate = localeDate.format("LL").toString();

  return `${formatedDate} (${t("translation:birthDateEditor.age.label", {
    age: calculateAge(date),
  })})`;
};

export const birthdateFormatOnlyDate = (date: {
  isValid: () => any;
  format: (arg0: string) => {
    (): any;
    new (): any;
    toString: { (): any; new (): any };
  };
}) => {
  if (!date || !date.isValid()) {
    return null;
  }

  const formatedDate = date.format("DD.MM.YYYY").toString();
  return formatedDate;
};

export const displayUtcToLocal = (date: moment.MomentInput) =>
  moment.utc(date).local().format("YYYY/MM/DD hh:mm A");

export const displayUtcToLocalLanguage = (date: moment.MomentInput, culture = "en") =>
  moment.utc(date).locale(culture).format("DD MMMM YYYY");

export const currentYear = () => moment().format("YYYY");

export const distanceToNowShort = (date: moment.MomentInput) => {
  const duration = moment.duration(moment.utc().diff(moment.utc(date)));
  const durationStr = duration.humanize({ s: 1 });
  return durationStr;
};

export const getLocalDateFromUtc = (date: Date): Date => {
  return moment.utc(date).local().toDate();
};

export const getDuration = (date: moment.MomentInput) =>
  moment.duration(moment.utc().diff(moment.utc(date)));

export const getYearsSince = (date: moment.MomentInput) =>
  moment.duration(moment.utc().startOf("day").diff(moment.utc(date).startOf("day"))).asYears();

export const getStartOfDay = (date: moment.MomentInput) => moment(date).startOf("day").toDate();

export const getTimeStringFromDate = (date: Date): string => {
  const hours = moment(date).hours();
  const minutes = moment(date).minutes();
  return `${numberRepresentation(hours)}:${numberRepresentation(minutes)}`;
};

export const numberRepresentation = (nr: number): string =>
  `${nr.toLocaleString("en-US", { minimumIntegerDigits: 2 })}`;

export const createDateTime = (date: Date, time: string): Date => {
  if (time.indexOf(":") <= 0) {
    throw Error("Incorrect time format, must be hh:mm");
  }
  const parts = time.split(":");
  const hours: number = parseInt(parts[0]);
  const minutes: number = parseInt(parts[1]);
  const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
  return result;
};

export const convertToLocalTimezone = (utcDate: Date): Date => {
  const momentUTCDate = moment.utc(utcDate);
  return momentUTCDate.local().toDate();
};

export const getLongDateString = (date: Date): string => {
  return moment(date).format("dddd, MMMM DD, YYYY");
};

export const getMonthName = (date: Date): string => moment(date).format("MMMM");

export const ticksToDateTime = (ticks: number): Date => {
  const epochTicks = 621355968000000000;
  const ticksPerMillisecond = 10000; // whoa!
  const maxDateMilliseconds = 8640000000000000; // via http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1

  if (isNaN(ticks)) {
    //      0001-01-01T00:00:00.000Z
    throw new Error("ticks is not a number!");
  }

  // convert the ticks into something javascript understands
  const ticksSinceEpoch = ticks - epochTicks;
  const millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond;

  if (millisecondsSinceEpoch > maxDateMilliseconds) {
    //      +035210-09-17T07:18:31.111Z
    throw new Error("Value is too large!");
  }

  // output the result in something the human understands
  const date = new Date(millisecondsSinceEpoch);

  return date;
};

export const getUTCDateFromString = (dateString: string): Date => {
  const tempStartDate = new Date(Date.parse(dateString));
  const utcStartDate = new Date(
    Date.UTC(
      tempStartDate.getFullYear(),
      tempStartDate.getMonth(),
      tempStartDate.getDate(),
      tempStartDate.getHours(),
      tempStartDate.getMinutes(),
    ),
  );
  return utcStartDate;
};

export const getDataArrayInLocalTimezone = <T extends ActivityModel>(events: T[]): T[] => {
  const entities = events.map((e) => changeDatesToLocalTimezone(e));
  return entities;
};

export const changeDatesToLocalTimezone = <T extends ActivityModel>(activity: T): T => {
  activity.eventStartDate = convertToLocalTimezone(activity.eventStartDate || new Date());
  activity.eventEndDate = convertToLocalTimezone(activity.eventEndDate || new Date());
  return activity;
};

export const getDataInLocalTimezone = <T extends ActivityModel>(activity: T): T => {
  return changeDatesToLocalTimezone(activity);
};

const locales = {
  en,
  fr,
};

export const getFormattedDistanceToNow = (date: Date | number): string => {
  const options = {
    locale: {
      ...locales[i18n.language as keyof typeof locales],
      formatDistance: (unit: string, count: number) =>
        i18n.t([`Date.ShortDistance.${unit}`, "%dh"], { count }),
    },
  };

  return formatDistanceToNowStrict(date, options);
};

export const getRelativeDate = (date: Date | number): string => {
  return formatRelative(date, new Date(), {
    locale: locales[i18n.language as keyof typeof locales],
  });
};
